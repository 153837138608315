import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['previewContainer', 'fileField'];

  connect() {
    this.previewContainerTarget.addEventListener('click', this.openFileDialog.bind(this));
    this.fileFieldTarget.addEventListener('change', this.handleFileUpload.bind(this));
    this.images = [];
  }

  openFileDialog() {
    this.fileFieldTarget.click();
  }

  imageClicked(event) {
    
    const imageContainer = event.target.closest('.image-preview');
    const index = imageContainer.dataset.index;

    this.images.splice(index, 1);
    imageContainer.remove();
    this.updateFileInput();
  }

  handleFileUpload(event) {
    const files = Array.from(event.target.files);

    files.forEach((file, index) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        
        const preview = document.createElement('div');
        preview.classList.add('image-preview');
        preview.dataset.index = this.images.length;

        const img = document.createElement('img');
        img.setAttribute('src', e.target.result);

        const deleteIcon = document.createElement('div');
        deleteIcon.classList.add('delete-preview');

        preview.appendChild(img);
        preview.appendChild(deleteIcon);
        preview.addEventListener('click', this.imageClicked.bind(this));

        this.previewContainerTarget.insertAdjacentElement('afterend', preview);
        this.images.push(file);
        this.updateFileInput();
      };

      reader.readAsDataURL(file);
    });
  }

  updateFileInput() {
    const dataTransfer = new DataTransfer();
    this.images.forEach(file => dataTransfer.items.add(file));
    this.fileFieldTarget.files = dataTransfer.files;
  }
}
